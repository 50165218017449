/* @font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot?-rdnm34');
	src:url('../fonts/icomoon/icomoon.eot?#iefix-rdnm34') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.woff?-rdnm34') format('woff'),
		url('../fonts/icomoon/icomoon.ttf?-rdnm34') format('truetype'),
		url('../fonts/icomoon/icomoon.svg?-rdnm34#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
} */

div.cs-skin-elastic {
  background: transparent;
  font-size: 1.5em;
  font-weight: 700;
  color: #5b8583;
}

@media screen and (max-width: 30em) {
  div.cs-skin-elastic {
    font-size: 1em;
  }
}

.cs-skin-elastic > span {
  background-color: #fff;
  z-index: 100;
}

.cs-skin-elastic > span::after {
  font-family: 'icomoon';
  content: '\e005';
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.cs-skin-elastic .cs-options {
  overflow: visible;
  background: transparent;
  opacity: 1;
  visibility: visible;
  padding-bottom: 1.25em;
  pointer-events: none;
}

.cs-skin-elastic.cs-active .cs-options {
  pointer-events: auto;
}

.cs-skin-elastic .cs-options > ul::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transform: scale3d(1, 0, 1);
  transform: scale3d(1, 0, 1);
  background: #fff;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.cs-skin-elastic.cs-active .cs-options > ul::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: none;
  transition: none;
  -webkit-animation: expand 0.6s ease-out;
  animation: expand 0.6s ease-out;
}

.cs-skin-elastic .cs-options ul li {
  opacity: 0;
  -webkit-transform: translate3d(0, -25px, 0);
  transform: translate3d(0, -25px, 0);
  -webkit-transition:
    opacity 0.15s,
    -webkit-transform 0.15s;
  transition:
    opacity 0.15s,
    transform 0.15s;
}

.cs-skin-elastic.cs-active .cs-options ul li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  -webkit-transition: none;
  transition: none;
  -webkit-animation: bounce 0.6s ease-out;
  animation: bounce 0.6s ease-out;
}

/* Optional delays (problematic in IE 11/Win) */
/*
.cs-skin-elastic.cs-active .cs-options ul li:first-child {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}

.cs-skin-elastic.cs-active .cs-options ul li:nth-child(2) {
	-webkit-animation-delay: 0.15s;
	animation-delay: 0.15s;
}

.cs-skin-elastic.cs-active .cs-options ul li:nth-child(3) {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

.cs-skin-elastic.cs-active .cs-options ul li:nth-child(4) {
	-webkit-animation-delay: 0.25s;
	animation-delay: 0.25s;
} 

/* with more items, more delays declarations are needed */

.cs-skin-elastic .cs-options span {
  background-repeat: no-repeat;
  background-position: 1.5em 50%;
  background-size: 2em auto;
  padding: 0.8em 1em 0.8em 4em;
}

.cs-skin-elastic .cs-options span:hover,
.cs-skin-elastic .cs-options li.cs-focus span,
.cs-skin-elastic .cs-options .cs-selected span {
  color: #1e4c4a;
}

.cs-skin-elastic .cs-options .cs-selected span::after {
  content: '';
}

/* .cs-skin-elastic .cs-options li.flag-france span {
	background-image: url(../img/france.svg);
} */

/* .cs-skin-elastic .cs-options li.flag-brazil span {
	background-image: url(../img/brazil.svg);
}

.cs-skin-elastic .cs-options li.flag-safrica span {
	background-image: url(../img/south-africa.svg);
} */

/* .cs-skin-elastic .cs-options li.flag-argentina span {
	background-image: url(../img/argentina.svg);
} */

@-webkit-keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
  }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
  }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
  }
}

@keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
  }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
    transform: scale3d(1, 1.2, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
    transform: scale3d(1, 0.85, 1);
  }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
    transform: scale3d(1, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
    transform: translate3d(0, -6px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/* Default custom select styles */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #f1f2f7;
  z-index: 100;
  width: 100%;
  max-width: 80px;
  margin-left: 25px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.cs-select:focus {
  outline: none; /* For better accessibility add a style for this in your skin */
}

.cs-select select {
  display: none;
}

.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 9px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Placeholder and selected option */

.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cs-select > span::after {
  content: '\f107';
  font-family: 'Fontawesome';
  right: 1em;
}

.cs-select .cs-selected span::after {
  content: '\2713';
  margin-left: 1em;
}

.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

div.cs-active {
  z-index: 200;
}

/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #f1f2f7;
  visibility: hidden;
}

.cs-select.cs-active .cs-options {
  visibility: visible;
}

.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.cs-select ul span {
  padding: 5px 15px;
}
.cs-select ul li {
  display: block;
}
.cs-select ul li.cs-focus span {
  background-color: #ddd;
}

/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  padding-left: 1em;
}

.cs-select li.cs-optgroup > span {
  cursor: default;
}
